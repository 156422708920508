import { render, staticRenderFns } from "./MarketingBlock.vue?vue&type=template&id=06731c7f&scoped=true&"
import script from "./MarketingBlock.vue?vue&type=script&lang=js&"
export * from "./MarketingBlock.vue?vue&type=script&lang=js&"
import style0 from "./MarketingBlock.vue?vue&type=style&index=0&id=06731c7f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06731c7f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiLazyImg: require('/opt/atlassian/pipelines/agent/build/components/UI/UiLazyImg.vue').default,UiLazyVideo: require('/opt/atlassian/pipelines/agent/build/components/UI/UiLazyVideo.vue').default})
